import React, { useState, useEffect } from "react";
import { Space, Table, Input } from "antd";
import axios from "axios";
import PaymentList from "../CalcComps/PopupModal";
import { Container } from "@mui/material";
import _ from "lodash";

const columns = [
  {
    title: "Calculation Number",
    dataIndex: "calculationNumber",
    key: "calculationNumber",
  },
  {
    title: "NIC",
    dataIndex: "NIC",
    key: "NIC",
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
  },
  {
    title: "Loan Amount",
    dataIndex: "loanAmount",
    key: "loanAmount",
  },
  {
    title: "Interest Rate",
    dataIndex: "interestRate",
    key: "interestRate",
  },
  {
    title: "Loan Period(Months)",
    key: "loanPeriod",
    dataIndex: "loanPeriod",
  },
  {
    title: "View Schedule",
    key: "View Schedule",
    render: (data) => (
      <Space size="middle">
        <PaymentList
          loanAmount={data.loanAmount}
          interestRate={data.interestRate}
          loanPeriod={data.loanPeriod}
          footerProps={false}
        />
      </Space>
    ),
  },
];

const { Search } = Input;

const App = () => {
  const [data, setData] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const getData = async () => {
    axios
      .get("/emiSaveSchedule")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSearch = (e) => {
    if (e.target.value === "") {
      getData();
    } else {
      setData(_.filter(data, (d) => d.NIC.includes(e.target.value)));
    }
  };

  return (
    <div>
      <Container maxWidth="xl">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: isSmallScreen ? "10%" : "2%",
          }}
        >
          <Search
            placeholder="Search by NIC"
            allowClear
            onChange={(e) => onSearch(e)}
            style={{
              width: isSmallScreen ? "100%" : "200px",
              marginBottom: isSmallScreen ? "10%" : "2%",
            }}
          />
        </div>
        <div
          style={{
            overflowX: "auto",
          }}
        >
          <Table
            columns={columns}
            dataSource={data}
            style={{ minWidth: "100%" }}
          />
        </div>
      </Container>
    </div>
  );
};

export default App;
