import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import CalcFace from "./CalcComps/CalcFace";

export default function SimpleContainer() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <CalcFace />
      </Container>
    </React.Fragment>
  );
}
