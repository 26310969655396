import React from "react";
import Main from "./Main";
import axios from "axios";
// axios.defaults.baseURL = "http://localhost:4000/api";
axios.defaults.baseURL = "https://01-loancalc.3orp16.easypanel.host/api";

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
