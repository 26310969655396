import React from "react";
import { Table } from "antd";
const columns = [
  {
    title: "EMI Number",
    dataIndex: "emiNumber",
    key: "emiNumber",
  },
  {
    title: "Installment",
    key: "installment",
    dataIndex: "installment",
    render: (text) => <b>{text}</b>,
  },
  {
    title: "Interest",
    key: "interest",
    dataIndex: "interest",
  },
  {
    title: "Capital",
    dataIndex: "capital",
    key: "capital",
  },
  {
    title: "Capital Balance",
    dataIndex: "capitalBalance",
    key: "capitalBalance",
  },
];

const App = ({ schedule }) => (
  <Table columns={columns} dataSource={schedule.schedule} />
);
export default App;
